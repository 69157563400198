import { Select, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

const HistorySelectField = ({
  value,
  handleChange,
  children,
}: {
  value: string;
  handleChange: (s: string) => void;
  children: ReactNode;
}) => (
  <Stack direction='row' alignItems='center' gap={1}>
    <Typography width='max-content'>Showing results for last: </Typography>
    <Select
      value={value}
      variant='outlined'
      onChange={(e) => handleChange(e.target.value)}
      size='small'
      sx={{ bgcolor: 'common.white' }}
    >
      {children}
    </Select>
  </Stack>
);

export default HistorySelectField;
